<template>
  <div class="main-z1">
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheader class="hidden-sm-and-down"></vheader>
        <ivheadera class="hidden-md-and-up"></ivheadera>
      </el-header>
      <div class="main">
        <div class="w">
          <div class="tit"><img src="../../assets/images/Z/Z1/tit.jpg" /></div>
          <div class="tc sign-prov" v-show="!signsuccess">
            <div class="check-box">
              <div class="check-d" @click="changecheck(1)">
                <div :class="clickcur == 1 ? 'check-i select' : 'check-i'"></div>
                <p class="check-p">验证邮箱</p>
              </div>
              <div class="check-d" @click="changecheck(2)">
                <div :class="clickcur == 2 ? 'check-i select' : 'check-i'"></div>
                <p class="check-p">验证手机</p>
              </div>
            </div>
            <div class="input-form" v-show="clickcur == 1">
              <el-form :rules="z1formrule1" :model="z1form1" ref="z1form1" label-width="100%" label-position="left" class="z1-form1">
                <el-form-item label="请输入邮箱" prop="email">
                  <el-input v-model="z1form1.email"></el-input>
                  <div class="sub-code" @click="getemailcode()" :disabled="getcode1.disablebtn" :class="getcode1.disablebtn ? 'disablebtn' : ''">{{ getcode1.emailgetcode }}</div>
                </el-form-item>
                <el-form-item label="输入验证码" prop="code"><el-input v-model="z1form1.code"></el-input></el-form-item>
                <el-form-item label="设置密码" prop="pass"><el-input v-model="z1form1.pass" type="password"></el-input></el-form-item>
                <el-form-item label="请再次输入密码" prop="checkpass"><el-input v-model="z1form1.checkpass" type="password"></el-input></el-form-item>
              </el-form>
            </div>
            <div class="input-form" v-show="clickcur == 2">
              <el-form :rules="z1formrule2" :model="z1form2" ref="z1form2" label-width="100%" label-position="left" class="z1-form1">
                <el-form-item label="请输入手机" prop="phone" class="poneinput">
                  <el-input v-model="z1form2.areacode" type="number" class="areacode">
                    <template slot="prepend">+</template>
                  </el-input>
                  <el-input v-model="z1form2.phone" type="number" class="phone"></el-input>
                  <div class="sub-code" @click="getphonecode()" :disabled="getcode2.disablebtn" :class="getcode2.disablebtn ? 'disablebtn' : ''">{{ getcode2.phonegetcode }}</div>
                </el-form-item>
                <el-form-item label="输入验证码" prop="code"><el-input v-model="z1form2.code"></el-input></el-form-item>
                <el-form-item label="设置密码" prop="pass"><el-input v-model="z1form2.pass" type="password"></el-input></el-form-item>
                <el-form-item label="请再次输入密码" prop="checkpass"><el-input v-model="z1form2.checkpass" type="password"></el-input></el-form-item>
              </el-form>
            </div>
            <el-checkbox v-model="checked" class="agree" >
              <span style="color:#8d8d8d;">我已阅读并同意</span>
              <router-link to="/Agreement" tag="a" target="_blank">CityPlus服务协议</router-link>
              <span style="color:#8d8d8d;">及</span>
              <router-link to="/Policy" tag="a" target="_blank">隐私权政策</router-link>
            </el-checkbox>
            <div class="btns">
              <a class="btn1" @click="$router.go(-1)">取消参与</a>
              <a class="btn2" @click="checkform()">验证成为CityPlus会员</a>
            </div>
          </div>

          <div class="tc sign-success" v-show="signsuccess">
            <i class="suc-icon el-icon-circle-check"></i>
            <p class="suc-der">恭喜你！注册会员成功</p>
            <div class="btns">
              <router-link class="btn1" to="/">返回首页</router-link>
              <a class="btn2" @click="goIndex">进入个人页面</a>
            </div>
          </div>
        </div>
      </div>
      <el-footer>
        <vfooter class="hidden-sm-and-down"></vfooter>
        <ivfooter class="hidden-md-and-up"></ivfooter>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { testphone, logon, checkname, testemail, getUserinfo } from '../../api';
import qs from 'qs';

export default {
  data () {
    var checkemail = (rule, value, callback) => {
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (reg.test(this.z1form1.email)) {
        callback()
      } else {
        callback(new Error('邮箱格式错误'))
      }
    };
    var checknewpass = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
      if (this.z1form1.pass.match(regx) == null) {
        callback(new Error('密码为数字+英文组合'));
      } else {
        callback();
      }
    };
    var checknewpass2 = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
      if (this.z1form1.pass != this.z1form1.checkpass) {
        callback(new Error('请再次输入相同密码'));
      } else {
        if (this.z1form1.pass.match(regx) == null) {
          callback(new Error('密码为数字+英文组合'));
        } else {
          callback();
        }
      }
    };
    var checknewpass3 = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
      if (this.z1form2.pass.match(regx) == null) {
        callback(new Error('密码为数字+英文组合'));
      } else {
        callback();
      }
    };
    var checknewpass4 = (rule, value, callback) => {
      var regx = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
      if (this.z1form2.pass != this.z1form2.checkpass) {
        callback(new Error('请再次输入相同密码'));
      } else {
        if (this.z1form2.pass.match(regx) == null) {
          callback(new Error('密码为数字+英文组合'));
        } else {
          callback();
        }
      }
    };
    return {
      checked: true,
      clickcur: 1,
      signsuccess: false,
      z1form1: {
        email: '',
        code: '',
        pass: '',
        checkpass: '',
        getcode: ''
      },
      z1form2: {
        areacode: '86',
        phone: '',
        code: '',
        pass: '',
        checkpass: '',
        getcode: ''
      },
      z1formrule1: {
        email: [{ type: 'email', required: true, message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        pass: [{ required: true, validator: checknewpass, trigger: 'blur' }, { min: 6, max: 24, message: '密码为6至24位数字+英文组合' }],
        checkpass: [{ required: true, validator: checknewpass2, trigger: 'blur' }, { min: 6, max: 24, message: '密码为6至24位数字+英文组合' }]
      },
      z1formrule2: {
        phone: [{ required: true, message: '请输入手机', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        pass: [{ required: true, validator: checknewpass3, trigger: 'blur' }, { min: 6, max: 24, message: '密码为6至24位数字+英文组合' }],
        checkpass: [{ required: true, validator: checknewpass4, trigger: 'blur' }, { min: 6, max: 24, message: '密码为6至24位数字+英文组合' }]
      },
      getcode1: {
        emailgetcode: '发送验证码',
        disablebtn: false,
        timer: null,
        count: ''
      },
      getcode2: {
        phonegetcode: '发送验证码',
        disablebtn: false,
        timer: null,
        count: ''
      }
    };
  },
  methods: {
    goIndex () {
      if (this.clickcur == 1) {
        getUserinfo(this.z1form1.email).then(res => {
          let userInfo = {
            'userId': res.data[0].SPONSOR_ID,
            'userName': res.data[0].USERNAME
          }
          userInfo = JSON.stringify(userInfo)
          localStorage.setItem('userInfo', userInfo)
          this.$router.push('/user')
        }).catch(error => {
          console.log(error)
          this.$message({
            showClose: true,
            message: '请求失败，请检查网络或联系管理员',
            type: 'error',
            center: true
          })
        })
      } else {
        let num = this.z1form2.areacode == '86' ? this.z1form2.phone : this.z1form2.areacode + this.z1form2.phone
        getUserinfo(num).then(res => {
          let userInfo = {
            'userId': res.data[0].SPONSOR_ID,
            'userName': res.data[0].USERNAME
          }
          userInfo = JSON.stringify(userInfo)
          localStorage.setItem('userInfo', userInfo)
          this.$router.push('/user')
        }).catch(error => {
          console.log(error)
          this.$message({
            showClose: true,
            message: '请求失败，请检查网络或联系管理员',
            type: 'error',
            center: true
          })
        })
      }
    },
    changecheck(i) {
      this.clickcur = i;
    },
    checkform() {
      if(this.checked){
        let formid, form, list;
        if (this.clickcur == 1) {
          formid = 'z1form1';
          form = this.z1form1;
          list = {
            USERNAME: this.z1form1.email,
            PASSWORD: this.getmd5(this.z1form1.pass),
            EMAIL: this.z1form1.email
          };
        } else {
          formid = 'z1form2';
          form = this.z1form2;
          list = {
            USERNAME: this.z1form2.phone,
            PASSWORD: this.getmd5(this.z1form2.pass),
            PHONE: this.z1form2.phone
          };
        }
        this.$refs[formid].validate(valid => {
          if (valid) {
            if (this.getmd5(String(form.code)) == form.getcode) {
              logon(qs.stringify(list)).then(res => {
                if (res.data.result == 'ok') {
                  this.signsuccess = true;
                }
              });
            } else {
              console.log('验证错误');
              this.$message({
                showClose: true,
                message: '验证错误，请检查验证码！',
                type: 'error',
                center: true
              });
              return false;
            }
          }
        });
      }else{
        this.$message({
          showClose: true,
          message: '请勾选《CityPlus用户注册协议》',
          type: 'error',
          center: true
        });
        return false;
      }
    },
    getemailcode() {
      this.$refs.z1form1.validateField('email', valid => {
        if (this.z1form1.email == '') {
          this.$message({
            showClose: true,
            message: '请输入邮箱',
            type: 'error',
            center: true
          });
          return false;
        } else if (valid) {
          this.$message({
            showClose: true,
            message: '邮箱格式错误',
            type: 'error',
            center: true
          });
          return false;
        } else {
          let em = this.z1form1.email;
          checkname(em).then(res => {
            console.log(res);
            if (res.data['new']['count(USERNAME)'] != 0) {
              this.$message({
                showClose: true,
                message: '该邮箱已被注册！',
                type: 'error',
                center: true
              });
              return false;
            } else {
              testemail(em).then(res => {
                console.log(res.data);
                if (typeof res.data == 'string') {
                  this.getcode1.emailgetcode = '发送验证码';
                  const TIME_COUNT = 60;
                  if (!this.getcode1.timer) {
                    this.getcode1.count = TIME_COUNT;
                    this.getcode1.emailgetcode = '发送验证码';
                    this.getcode1.timer = setInterval(() => {
                      if (this.getcode1.count > 0 && this.getcode1.count <= TIME_COUNT) {
                        this.getcode1.count--;
                        this.getcode1.emailgetcode = `${this.getcode1.count}s`;
                        this.getcode1.disablebtn = true;
                      } else {
                        this.getcode1.emailgetcode = '发送验证码';
                        this.getcode1.disablebtn = false;
                        clearInterval(this.getcode1.timer);
                        this.getcode1.timer = null;
                      }
                    }, 1000);
                  }
                  this.$message({
                    showClose: true,
                    message: '邮件已发送至该邮箱',
                    type: 'success',
                    center: true
                  });
                  this.z1form1.getcode = res.data;
                } else {
                  this.$message({
                    showClose: true,
                    message: '邮件发送失败',
                    type: 'error',
                    center: true
                  });
                  return false
                }
              });
            }
          });
        }
      });
    },
    getphonecode() {
      this.$refs.z1form2.validateField('phone', valid => {
        if (this.z1form1.phone == '') {
          this.$message({
            showClose: true,
            message: '请输入手机',
            type: 'error',
            center: true
          });
          return false;
        } else if (valid) {
          this.$message({
            showClose: true,
            message: '手机格式错误',
            type: 'error',
            center: true
          });
          return false;
        } else {
          let num = this.z1form2.areacode == '86' ? this.z1form2.phone : this.z1form2.areacode + this.z1form2.phone;
          let phone = this.z1form2.phone;
          checkname(phone).then(res => {
            console.log(res);
            if (res.data['new']['count(USERNAME)'] != 0) {
              this.$message({
                showClose: true,
                message: '该手机号已被注册！',
                type: 'error',
                center: true
              });
              return false;
            } else {
              testphone(num).then(res => {
                console.log(typeof res.data);
                if (!res.data) {
                  this.$message({
                    showClose: true,
                    message: '手机格式错误或今日发送次数上限',
                    type: 'error',
                    center: true
                  });
                } else if (typeof res.data == 'string') {
                  this.getcode2.phonegetcode = '发送验证码';
                  const TIME_COUNT = 60;
                  if (!this.getcode2.timer) {
                    this.getcode2.count = TIME_COUNT;
                    this.getcode2.phonegetcode = '发送验证码';
                    this.getcode2.timer = setInterval(() => {
                      if (this.getcode2.count > 0 && this.getcode2.count <= TIME_COUNT) {
                        this.getcode2.count--;
                        this.getcode2.phonegetcode = `${this.getcode2.count}s`;
                        this.getcode2.disablebtn = true;
                      } else {
                        this.getcode2.phonegetcode = '发送验证码';
                        this.getcode2.disablebtn = false;
                        clearInterval(this.getcode2.timer);
                        this.getcode2.timer = null;
                      }
                    }, 1000);
                  }
                  this.$message({
                    showClose: true,
                    message: '短信验证码已发送',
                    type: 'success',
                    center: true
                  });
                  this.z1form2.getcode = res.data;
                  console.log(this.z1form2.getcode);
                }
              });
            }
          });
        }
      });
    },
    getmd5(code){
      return this.$md5(code)
    }
  },
  mounted() {

  }
};
</script>

<style scoped lang="stylus">
.main-z1
  .main
    padding 2rem 0 2rem
    background #fff
  .tit
    text-align left
    padding-bottom 0.6rem
  .tc
    text-align left
    max-width 9.0888rem
    min-height 11.2rem
    margin 0 auto 0
    .input-form
      padding 0 0.6rem 0.8rem 0.6rem
    .btns
      text-align center
      margin-bottom 0.4rem
      a
        width 3.3148rem
        height 0.8333rem
        display inline-block
        line-height 0.8333rem
        font-size 0.2962rem
      .btn1
        color #515555
        background #fff
        border 0.0185rem solid #d3d3d3
        margin 0 0.1rem 0 0
      .btn2
        color #fff
        background #a5cd39
        border 0.0185rem solid #a5cd39
        margin 0 0 0 0.1rem
    &.sign-prov
      .check-box
        padding 0.8rem 0.6rem 0 0.6rem
        .check-d
          cursor pointer
          display inline-block
          padding 0 1rem 0 0
          .check-i
            display inline-block
            vertical-align middle
            width 0.2592rem
            height 0.2592rem
            border-radius 50%
            border 0.0185rem solid #8e8e8e
            position relative
            margin 0 0.2rem 0 0
            transition all 0.2s
            &.select
              border 0.0185rem solid #00b4d7
              transition all 0.2s
              &::before
                content ''
                width 0.1481rem
                height 0.1481rem
                border-radius 50%
                position absolute
                top 50%
                left 50%
                margin -(@height / 2) 0 0 0 - (@width / 2)
                background #00b4d7
          p
            color #8d8d8d
            font-size 0.2962rem
            display inline-block
            vertical-align middle
      .input-form
        padding 0.6rem 0.6rem 0.2rem 0.6rem
        .cnt-input
          .input
            width 73%
          .sub-code
            width 2.3333rem
            height 0.7407rem
            background #a5cd39
            border none
            outline none
            text-align center
            line-height 0.7407rem
            color #fff
            font-size 0.2962rem
            display inline-block
            vertical-align bottom
      .agree
        padding 0 0 0.8rem 0.6rem
        font-size 0.2222rem
        color #8d8d8d
        a
          color #00b6cd
      .el-checkbox__input.is-checked+.el-checkbox__label
        color #8d8d8d
      .btn2
        width 4.36rem
    &.sign-success
      text-align center
      padding-top 1.8rem
      .suc-icon
        color #a5cd39
        font-size 0.9407rem
      .suc-der
        color #8d8d8d
        font-size 0.2962rem
        padding 0.2rem 0 0.6rem
@media screen and (max-width: 990px){
  .main-z1{
    .main{
      font-size 0.4444rem
      .w{
        .tit{
          padding 0 .37rem
          img{
            width 40%
          }
        }
      }
    }
    .tc{
      .check-box{
        .check-d{
          p{
            font-size 0.3333rem
          }
        }
      }
      .btns{
        a{
          height 1.0333rem
          line-height 1.0333rem
          font-size 0.3333rem
        }
      }
    }

    .tc.sign-success{
      .suc-der{
        font-size 0.3333rem
      }
    }
  }
}
</style>
<style lang="stylus">
.main-z1{
  .main{
    .w{
      .tc.sign-prov{
        .z1-form1{
          .el-form-item__label{
            color #8d8d8d
            font-size 0.2962rem
          }
          .el-form-item{
            margin-bottom .3rem
            &.poneinput{
              .el-input-group__prepend{
                padding 0 .2rem
                border-radius 0
              }
              .areacode{
                width 28%
              }
              .phone{
                width 72%
                padding 0 0 0 .2rem
              }
            }
            .el-form-item__content{
              margin 0 !important
              .el-input__inner{
                border-radius 0
                border 0.0185rem solid #d1d1d1
                background #f7f9fa
              }
            }
            &:first-child{
              position relative
              .sub-code{
                width 2.3333rem
                height 40px
                background #a5cd39
                border none
                outline none
                text-align center
                line-height 40px
                color #fff
                font-size 0.2962rem
                display inline-block
                vertical-align bottom
                position absolute
                right 0
                cursor pointer
                transition all .2s
                &.disablebtn{
                  cursor no-drop
                  background #b3e19d
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 990px){
  .main-z1{
    .main{
      .w{
        .tc{
          &.sign-prov{
            .z1-form1{
              .el-form-item__label{
                font-size 0.3333rem
              }
            }
          }
        }
      }
    }
  }
}
</style>
